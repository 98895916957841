<template>
  <div class="notifications">
    <div class="row">
      <div class="top-row w-100">
        <div
          class="create-new-btn shadow-sm"
          v-if="!activeTab"
          @click="createNewNotification"
        >
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Notification
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archive
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <template v-if="activeTab === 0">
          <SentNotifications
            ref="sentNotificationsRef"
            @editNotification="editNotificationActive"
          />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 1">
          <ArchivedNotifications
            ref="archivedNotificationsRef"
            @editNotification="editNotificationArchive"
          />
        </template>
      </b-tab>
    </b-tabs>
    <template v-if="newNotification">
      <NewNotification
        ref="newNotificationRef"
        @addedNewNotification="addedNewNotification"
      />
    </template>

    <template v-if="edit === 'active' || edit === 'archive'">
      <EditNotification
        ref="editNotificationRef"
        @editedNotification="editedNotification"
        :notificationToEdit="notificationToEdit"
        @close="editClosed"
      />
    </template>
  </div>
</template>

<script>
import SentNotifications from "@/view/pages/notifications/SentNotifications";
import ArchivedNotifications from "@/view/pages/notifications/ArchivedNotifications";
import NewNotification from "@/view/pages/notifications/NewNotification";
import EditNotification from "@/view/pages/notifications/EditNotification";
export default {
  components: {
    SentNotifications,
    ArchivedNotifications,
    NewNotification,
    EditNotification,
  },
  data() {
    return {
      activeTab: 0,
      notificationToEdit: null,
      edit: null,
      newNotification: false,
    };
  },
  methods: {
    changeTab: function (index) {
      this.activeTab = index;
    },
    editClosed: function () {
      setTimeout(() => {
        this.notificationToEdit = null;
        this.edit = null;
      }, 100);
    },
    createNewNotification: function () {
      this.newNotification = true;
      setTimeout(() => {
        this.$refs.newNotificationRef.showModal();
      }, 100);
    },
    addedNewNotification: function () {
      this.$refs.newNotificationRef.closeModal();
      this.$refs.sentNotificationsRef.update();
    },

    editedNotification: function () {
      this.$refs.editNotificationRef.closeModal();
      if (this.edit === "active") {
        this.$refs.sentNotificationsRef.update();
      } else {
        this.$refs.archivedNotificationsRef.update();
      }
      this.edit = null;
      this.notificationToEdit = null;
    },
    editNotificationActive: function (notification) {
      console.log("notification 1", notification);
      this.edit = "active";
      this.editNotification(notification);
      setTimeout(() => {
        this.$refs.editNotificationRef.showModal();
      }, 100);
    },
    editNotificationArchive: function (notification) {
      console.log("notification 2", notification);
      this.edit = "archive";
      this.editNotification(notification);
      setTimeout(() => {
        this.$refs.editNotificationRef.showModal();
      }, 100);
    },
    editNotification: function (notification) {
      this.notificationToEdit = notification;
    },
    editedArchivedNotification: function () {
      this.$refs.editNotificationRef.closeModal();
      this.$refs.sentNotificationsRef.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .create-new-btn {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      font-weight: 700;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
