<template>
  <div class="sent-notifications">
    <h3 class="heading-text">Archived Notification</h3>
    <template v-if="fetching || processing">
      <div class="overlay">
        <div class="d-flex">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
    </template>
    <template v-else-if="!fetching && networkError === null">
      <div class="items">
        <b-col
          cols="12"
          md="6"
          class="d-flex"
          v-for="(singleNotification, index) in allNotifications"
          :key="singleNotification.updatedAt"
        >
          <SingleNotification
            :archived="true"
            :notification="singleNotification"
            :index="index"
            @editNotification="editNotification"
            @archiveAction="unarchive"
          />
        </b-col>
      </div>
    </template>
    <template v-else-if="!fetching && networkError !== null">
      <div class="d-flex flex-column align-items-center w-100">
        <div class="text-danger text-center display-4 p-4">API Error</div>
        <div class="text-danger text-center lead pb-4">
          Error while fetchting active notifications. Please refresh to try
          again.
        </div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SingleNotification from "@/view/pages/notifications/SingleNotification";
import { mapState, mapGetters } from "vuex";
import { GET_ARCHIVED_NOTIFICATIONS } from "@/core/services/store/notifications.module";
import ApiService from "@/core/services/api.service";
export default {
  components: {
    SingleNotification,
  },
  data: () => ({
    processing: false,
  }),
  methods: {
    ...mapGetters(["getAllNotifications"]),
    update: async function () {
      await this.$store.dispatch(GET_ARCHIVED_NOTIFICATIONS);
    },

    editNotification: function (index) {
      const notification = this.allNotifications[index];
      this.$emit("editNotification", notification);
    },
    unarchive: async function (notificationId) {
      this.processing = true;
      await ApiService.post("/notifications/archive", {
        action: "unarchive",
        notificationId,
      });
      await this.update();
      this.processing = false;
    },
  },
  computed: {
    ...mapState({
      allNotifications: (state) => state.notifications.archivedNotifications,
      fetching: function (state) {
        return state.notifications.notificationsFetching;
      },
      networkError: function (state) {
        return state.notifications.notificationsError;
      },
    }),
  },
  mounted() {
    this.$store.dispatch(GET_ARCHIVED_NOTIFICATIONS);
  },
};
</script>

<style lang="scss" scoped>
.sent-notifications {
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 25px;
    top: 0;
    left: 0;
    z-index: 1;
  }
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
